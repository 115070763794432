import { autoinject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';
import { ConfigMapper } from './config-mapper';

import moment from 'moment';

const logger = LogManager.getLogger('CampaignLiveDashboardStats');

@autoinject
export class CampaignLiveDashboardStats {
  constructor(private configMapper: ConfigMapper) {}

  public columnConfig = {
    Campaign: {
      value: (c) => c.campaignName + ` (${c.diallerType})`,
      width: 'ignore',
      disabled: true,
    },

    'Dial Attempts (day)': {
      value: (c) => c.totalDialAttemptsDay,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalDialAttemptsDay;
        return ` style="${this.configMapper.getColourConfig(
          'Dial Attempts (day)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Dial Attempts',
      },
    },
    'Dial Attempts (30 min)': {
      value: (c) => c.totalDialAttempts30Min,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalDialAttempts30Min;
        return ` style="${this.configMapper.getColourConfig(
          'Dial Attempts (30 min)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Dial Attempts',
      },
    },
    'Abandoned (day)': {
      value: (c) => c.totalAbandonedDay,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalAbandonedDay;
        return ` style="${this.configMapper.getColourConfig(
          'Abandoned (day)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Abandoned',
      },
    },
    'Abandoned (30 min)': {
      value: (c) => c.totalAbandoned30Min,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalAbandoned30Min;
        return ` style="${this.configMapper.getColourConfig(
          'Abandoned (30 min)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Abandoned',
      },
    },
    'Busy (day)': {
      value: (c) => c.totalBusyDay,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalBusyDay;
        return ` style="${this.configMapper.getColourConfig(
          'Busy (day)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Busy',
      },
    },
    'Busy (30 min)': {
      value: (c) => c.totalBusy30Min,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalBusy30Min;
        return ` style="${this.configMapper.getColourConfig(
          'Busy (30 min)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Busy',
      },
    },
    'No Answer (day)': {
      value: (c) => c.totalNoAnswerDay,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalNoAnswerDay;
        return ` style="${this.configMapper.getColourConfig(
          'No Answer (day)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'No Answer',
      },
    },
    'No Answer (30 min)': {
      value: (c) => c.totalNoAnswer30Min,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalNoAnswer30Min;
        return ` style="${this.configMapper.getColourConfig(
          'No Answer (30 min)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'No Answer',
      },
    },
    'Voicemail (day)': {
      value: (c) => c.totalVoicemailDay,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalVoicemailDay;
        return ` style="${this.configMapper.getColourConfig(
          'Voicemail (day)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Voicemail',
      },
    },
    'Voicemail (30 min)': {
      value: (c) => c.totalVoicemail30Min,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalVoicemail30Min;
        return ` style="${this.configMapper.getColourConfig(
          'Voicemail (30 min)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Voicemail',
      },
    },
    'Right Party (day)': {
      value: (c) => c.totalRightPartyContactDay,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalRightPartyContactDay;
        return ` style="${this.configMapper.getColourConfig(
          'Right Party (day)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Right Party',
      },
    },
    'Right Party (30 min)': {
      value: (c) => c.totalRightPartyContact30Min,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalRightPartyContact30Min;
        return ` style="${this.configMapper.getColourConfig(
          'Right Party (30 min)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Right Party',
      },
    },
    'Wrong Party (day)': {
      value: (c) => c.totalWrongPartyContactDay,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalWrongPartyContactDay;
        return ` style="${this.configMapper.getColourConfig(
          'Wrong Party (day)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Wrong Party',
      },
    },
    'Wrong Party (30 min)': {
      value: (c) => c.totalWrongPartyContact30Min,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalWrongPartyContact30Min;
        return ` style="${this.configMapper.getColourConfig(
          'Wrong Party (30 min)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Wrong Party',
      },
    },
    'Invalid Number (day)': {
      value: (c) => c.totalInvalidNumberDay,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalInvalidNumberDay;
        return ` style="${this.configMapper.getColourConfig(
          'Invalid Number (day)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Invalid Number',
      },
    },
    'Invalid Number (30 min)': {
      value: (c) => c.totalInvalidNumber30Min,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.totalInvalidNumber30Min;
        return ` style="${this.configMapper.getColourConfig(
          'Invalid Number (30 min)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Invalid Number',
      },
    },

    'Remaining [Ready]': {
      value: (c) => `${c.remainingToday} [${c.remainingTodayReady}]`,
      style: (c) => {
        const value = c.remainingToday;
        return (
          '" style="' +
          this.configMapper.getColourConfig('Remaining [Ready]', value)
        );
      },
      cssClass: 'u-position-relative square-component',
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Prospects',
        expandCollapseGroup: 'Remaining [Ready]',
        expandCollapseHeader: true,
      },
    },
    Completed: {
      value: (c) => c.completed,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.completed;
        return (
          '" style="' + this.configMapper.getColourConfig('Completed', value)
        );
      },
      data: {
        unitOfMeasurement: 'Prospects',
        expandCollapseGroup: 'Completed',
        expandCollapseHeader: true,
      },
    },
    'Online Agents': {
      value: (c) => c.agentsOnline,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.agentsOnline;
        return (
          '" style="' +
          this.configMapper.getColourConfig('Online Agents', value)
        );
      },
      data: {
        unitOfMeasurement: 'Agents',
        expandCollapseGroup: 'Online Agents',
        expandCollapseHeader: true,
      },
    },
    'On Duty Agents': {
      value: (c) => c.agentsOnDuty,
      style: (c) => {
        const value = c.agentsOnDuty;
        return (
          '" style="' +
          this.configMapper.getColourConfig('On Duty Agents', value)
        );
      },
      cssClass: 'u-position-relative square-component',
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Agents',
        expandCollapseGroup: 'On Duty Agents',
        expandCollapseHeader: true,
      },
    },
    'Waiting Agents': {
      value: (c) => c.agentsWaiting,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.agentsWaiting;
        return (
          '" style="' +
          this.configMapper.getColourConfig('Waiting Agents', value)
        );
      },
      data: {
        unitOfMeasurement: 'Agents',
        expandCollapseGroup: 'Waiting Agents',
        expandCollapseHeader: true,
      },
    },
    'Conversing Agents': {
      value: (c) => c.agentsConversing,
      style: (c) => {
        const value = c.agentsConversing;
        return (
          '" style="' +
          this.configMapper.getColourConfig('Conversing Agents', value)
        );
      },
      cssClass: 'u-position-relative square-component',
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Agents',
        expandCollapseGroup: 'Conversing Agents',
        expandCollapseHeader: true,
      },
    },
    'Not Responding Agents': {
      value: (c) => c.agentsNotResponding,
      style: (c) => {
        const value = c.agentsNotResponding;
        return (
          '" style="' +
          this.configMapper.getColourConfig('Not Responding Agents', value)
        );
      },
      cssClass: 'u-position-relative square-component',
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Agents',
        expandCollapseGroup: 'Not Responding Agents',
        expandCollapseHeader: true,
      },
    },
    Pending: {
      value: (c) => c.pending,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.pending;
        return (
          '" style="' + this.configMapper.getColourConfig('Pending', value)
        );
      },
      data: {
        unitOfMeasurement: 'Prospects',
        expandCollapseGroup: 'Pending',
        expandCollapseHeader: true,
      },
    },
    Successful: {
      value: (c) => c.successful,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.successful;
        return (
          '" style="' + this.configMapper.getColourConfig('Successful', value)
        );
      },
      data: {
        unitOfMeasurement: 'Prospects',
        expandCollapseGroup: 'Successful',
        expandCollapseHeader: true,
      },
    },
    Unsuccessful: {
      value: (c) => c.unsuccessful,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.unsuccessful;
        return (
          '" style="' + this.configMapper.getColourConfig('Unsuccessful', value)
        );
      },
      data: {
        unitOfMeasurement: 'Prospects',
        expandCollapseGroup: 'Unsuccessful',
        expandCollapseHeader: true,
      },
    },
    'Offline Agents': {
      value: (c) => c.agentsOffline,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.agentsOffline;
        return (
          '" style="' +
          this.configMapper.getColourConfig('Offline Agents', value)
        );
      },
      data: {
        unitOfMeasurement: 'Agents',
        expandCollapseGroup: 'Offline Agents',
        expandCollapseHeader: true,
      },
    },
    'Off Duty Agents': {
      value: (c) => c.agentsOffDuty,
      style: (c) => {
        const value = c.agentsOffDuty;
        return (
          '" style="' +
          this.configMapper.getColourConfig('Off Duty Agents', value)
        );
      },
      cssClass: 'u-position-relative square-component',
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Agents',
        expandCollapseGroup: 'Off Duty Agents',
        expandCollapseHeader: true,
      },
    },
    'Carried Over': {
      value: (c) => c.carriedOver,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.carriedOver;
        return (
          '" style="' + this.configMapper.getColourConfig('Carried Over', value)
        );
      },
      data: {
        unitOfMeasurement: 'Prospects',
        expandCollapseGroup: 'Carried Over',
        expandCollapseHeader: true,
      },
    },
    'New Prospects': {
      value: (c) => c.newProspects,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.newProspects;
        return (
          '" style="' +
          this.configMapper.getColourConfig('New Prospects', value)
        );
      },
      data: {
        unitOfMeasurement: 'Prospects',
        expandCollapseGroup: 'New Prospects',
        expandCollapseHeader: true,
      },
    },
    'Not Served [Ready]': {
      value: (c) => `${c.notServed} [${c.notServedReady}]`,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.notServed;
        return (
          '" style="' +
          this.configMapper.getColourConfig('Not Served [Ready]', value)
        );
      },
      data: {
        unitOfMeasurement: 'Prospects',
        expandCollapseGroup: 'Not Served [Ready]',
        expandCollapseHeader: true,
      },
    },
    'Requeued (Today) [Ready]': {
      value: (c) => `${c.requeuedToday} [${c.requeuedTodayReady}]`,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.requeuedToday;
        return (
          '" style="' +
          this.configMapper.getColourConfig('Requeued (Today) [Ready]', value)
        );
      },
      data: {
        unitOfMeasurement: 'Prospects',
        expandCollapseGroup: 'Requeued (Today) [Ready]',
        expandCollapseHeader: true,
      },
    },
    'Requeued (Carried Over) [Ready]': {
      value: (c) => `${c.requeuedCarriedOver} [${c.requeuedCarriedOverReady}]`,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.requeuedCarriedOver;
        return (
          '" style="' +
          this.configMapper.getColourConfig(
            'Requeued (Carried Over) [Ready]',
            value
          )
        );
      },
      data: {
        unitOfMeasurement: 'Prospects',
        expandCollapseGroup: 'Requeued (Carried Over) [Ready]',
        expandCollapseHeader: true,
      },
    },
    Stale: {
      value: (c) => c.stale,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.stale;
        return '" style="' + this.configMapper.getColourConfig('Stale', value);
      },
      data: {
        unitOfMeasurement: 'Prospects',
        expandCollapseGroup: 'Stale',
        expandCollapseHeader: true,
      },
    },
    'Avg Handle Time (Lead)': {
      value: (c) => this.configMapper.createTimer(c, 'averageHandleTime'),
      align: 'right',
      width: 60,
      style: (c) => {
        let value = c.averageHandleTime;
        if (value && value !== '--') {
          value = moment().diff(value, 'seconds');
        }
        return (
          '" style="' +
          this.configMapper.getColourConfig('Avg Handle Time (Lead)', value)
        );
      },
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Avg Handle Time (Lead)',
        expandCollapseHeader: true,
      },
    },
    '% In SLA': {
      value: (c) => c.percentInSLA,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.percentInSLA;
        return (
          '" style="' + this.configMapper.getColourConfig('% In SLA', value)
        );
      },
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: '% In SLA',
        expandCollapseHeader: true,
      },
    },
    '% Out Of SLA': {
      value: (c) => c.percentOutSLA,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.percentOutSLA;
        return (
          '" style="' + this.configMapper.getColourConfig('% Out Of SLA', value)
        );
      },
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: '% Out Of SLA',
        expandCollapseHeader: true,
      },
    },
    '% In SLA (30min)': {
      value: (c) => c.percentInSLAHalfHour,
      style: (c) => {
        const value = c.percentInSLAHalfHour;
        const colorConfig = this.configMapper.getColourConfig(
          '% In SLA (30min)',
          value,
          true
        );
        return '" style="' + colorConfig;
      },
      cssClass: 'u-position-relative square-component',
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: '% In SLA (30min)',
        expandCollapseHeader: true,
      },
    },
    'Connected Calls (agent)': {
      value: (c) => c.callCount,
      align: 'right',
      width: 60,
      style: (c) => {
        const value = c.callCount;
        return ` style="${this.configMapper.getColourConfig(
          'Connected Calls (agent)',
          value
        )}"`;
      },
      data: {
        unitOfMeasurement: 'Calls',
        expandCollapseGroup: 'Connected Calls',
      },
    },
    // 'Overflow Calls': {
    //   // value: (c) => c.totalOverflowCalls,
    //   value: (c) => {
    //     console.log(' ::>> OVERFLOWWW DATAAAA!!!!! >>>> ', c);
    //     return c.totalOverflowCalls;
    // },
    //   align: 'right',
    //   width: 60,
    //   style: (c) => {
    //     const value = c.totalOverflowCalls;
    //     return ` style="${this.configMapper.getColourConfig(
    //       'Overflow Calls',
    //       value
    //     )}"`;
    //   },
    //   data: {
    //     unitOfMeasurement: 'Calls',
    //     expandCollapseGroup: 'Overflow Calls',
    //   },
    // },
    'View Prospect List': {
      value: (c) => `
      <div class="o-utility-button pull-right o-utility-button--small" onclick="this.dispatchEvent(new CustomEvent('view', {bubbles: true,detail: '${c.campaignId}'}))">
        <div class="o-z-icon o-z-icon--view o-z-icon--size- o-z-icon--color-white"></div>    
        <div class="o-utility-button__corners o-utility-button__corners--top"></div>
        <div class="o-utility-button__corners o-utility-button__corners--bottom"></div>
      </div>`,
      width: 60,
      disabled: true,
    },
  };
}
