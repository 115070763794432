export function configure(config) {
  config
    .globalResources(
      PLATFORM.moduleName(
        'features/user/invitation/invitations/dashboardwidget/invitations-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/conversation/worktypes/dashboardwidget/work-types-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/sites/dashboardwidget/sites-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/services/dashboardwidget/services-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/finance/account/dashboardwidget/account-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/skills/dashboardwidget/skills-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/member/teams/dashboardwidget/teams-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/member/members/dashboardwidget/members-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/dashboardwidget/work-flows-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/qualityassurance/surveys/dashboardwidget/surveys-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/finance/telephonenumbers/dashboardwidget/telephone-numbers-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/interaction/call/interactionlog/dashboardwidget/interaction-logs-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/performancemanagement/reports/reports-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/reportschedule/widget/report-schedule-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/businesspartner/widget/business-partner-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/contactcenter/widget/contact-center-widget'
      ),
      PLATFORM.moduleName('features/organisation/alert/widget/alert-widget'),
      PLATFORM.moduleName(
        'features/media/media/audiofiles/dashboardwidget/audio-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/mailbox/dashboardwidget/mailbox-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/cannedresponses/widget/canned-responses-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/dispositioncodes/dashboardwidget/disposition-codes-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/contactfields/dashboardwidget/contact-fields-dashboard-widget'
      ),
      PLATFORM.moduleName('features/integrations/widget/integrations-widget'),
      PLATFORM.moduleName('features/im/widget/im-widget'),
      PLATFORM.moduleName(
        'features/interaction/emergencyAnnouncement/widget/emergency-announcement-widget'
      ),
      PLATFORM.moduleName(
        'features/interaction/loadbalancer/widget/load-balancer-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/transferlist/widget/transfer-list-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/bypass-cos/widget/bypass-cos-widget'
      ),
      PLATFORM.moduleName('features/monitoring/widget/monitoring-widget'),
      PLATFORM.moduleName(
        'features/organisation/organisation/post-interaction-processing/widget/post-interaction-processing-widget'
      ),
      PLATFORM.moduleName(
        'features/interaction/call/interactionlog/searchablevariables/dashboardwidget/searchable-variables-dashboard-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/sms-gateways/widget/sms-gateways-widget'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/auto-logout/widget/auto-logout-dashboard-widget'
      )
    )

    // Dialogs
    .globalResources(
      PLATFORM.moduleName(
        'features/organisation/member/routing-status/channel-selection/channel-selection-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/conversation/interaction/incoming-interaction-dialog'
      ),
      PLATFORM.moduleName(
        'features/campaigns/campaign/addcampaign/add-campaign-dialog'
      ),
      PLATFORM.moduleName(
        'features/campaigns/campaign/action-menu/campaign-action-menu-dialog'
      ),
      PLATFORM.moduleName(
        'features/campaigns/campaign/setoperatinghours/set-operating-hours-dialog'
      ),
      PLATFORM.moduleName(
        'features/campaigns/campaign/exportprospects/export-prospects-dialog'
      ),
      PLATFORM.moduleName(
        'features/campaigns/campaign/editcampaign/edit-campaign-dialog'
      ),
      PLATFORM.moduleName(
        'features/campaigns/campaign/confirmarchive/confirm-archive'
      ),
      PLATFORM.moduleName(
        'features/campaigns/campaign/confirmremoveschedule/confirm-remove-schedule'
      ),
      PLATFORM.moduleName(
        'features/campaigns/campaign/editrules/edit-rules-dialog'
      ),
      PLATFORM.moduleName(
        'features/campaigns/campaign/prospectlist/uploadresultsdialog/upload-results-dialog'
      ),
      PLATFORM.moduleName(
        'features/campaigns/campaign/prospectlist/clearremainingprospects/clear-remaining-prospects-dialog'
      ),
      PLATFORM.moduleName(
        'features/_manual/createdorganisation/created-organisation'
      ),
      PLATFORM.moduleName(
        'features/dashboard/teamleader/components/agentmanifest/agent-overview-dialog'
      ),
      PLATFORM.moduleName('features/diagnostics/foo/bar'),
      PLATFORM.moduleName(
        'features/finance/account/3d-secure-dialog/three-d-secure-dialog'
      ),
      PLATFORM.moduleName(
        'features/finance/account/numbers/purchasenumbers/purchase-numbers'
      ),
      PLATFORM.moduleName(
        'features/finance/account/numbers/displaypurchasednumbers/return-number-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/member/members/_assign-members/assign-member-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/member/members/_unassign-members/unassign-member-dialog'
      ),
      PLATFORM.moduleName(
        'features/integrations/microsoft-365/microsoft-365-platform-api-dialog'
      ),
      PLATFORM.moduleName(
        'features/integrations/microsoft-365/configure-microsoft-365-emails/configure-microsoft-365-emails'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/verint/verint-platform-api'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/verint-analytics/speech/verint-speech-analytics-configuration'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/verint-analytics/text/verint-text-analytics-configuration'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/verint/verint-configuration'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/verint/verint-realtime-configuration'
      ),
      PLATFORM.moduleName(
        'features/interaction/call/interactionlog/viewinteraction/view-interaction-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/report/reports/export-dialog/export-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/call/interactionlog/interactionlogphraseanalytics/view-interaction-phrase-analytics-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/call/interactiontranscript/view-interaction-transcript-im-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/call/interactiontranscript/view-interaction-transcript-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/call/interactionlog/viewlinkedinteraction/view-linked-interaction-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/call/interactiontranscript/view-interaction-transcript-chat-dialog'
      ),
      PLATFORM.moduleName(
        'features/dialogs/somethingwentwrong/something-went-wrong'
      ),
      PLATFORM.moduleName(
        'features/interaction/dashboard/live-dashboard/waitinginteractions/selectMember/select-member-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/emergencyAnnouncement/edit/edit-emergency-announcement-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/emergencyAnnouncement/add/add-emergency-announcement-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/external-reference/external-reference-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/subflow/flow-versions/flow-version-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/flow-versions/flow-version-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/assign-number/assign-number-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/assign-chat-widget/assign-chat-widget-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/view-flows-assigned-to-subflow/view-flows-assigned-to-subflow'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/assign-endpoint/assign-endpoint-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/assign-email/assign-email-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/assign-webhook/assign-webhook-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/assign-caller-id/assign-caller-id-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/configure-additional-data/configure-additional-data-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/loadbalancer/view/view-load-balancer-dialog'
      ),
      PLATFORM.moduleName('features/mailbox/steps/view-members/view-members'),
      PLATFORM.moduleName(
        'features/organisation/mailbox/viewmessage/mailbox-messages-dialog'
      ),
      PLATFORM.moduleName('features/mailbox/steps/view-audio/view-audio'),
      PLATFORM.moduleName(
        'features/media/media/audiofiles/group/add-audio-group-dialog'
      ),
      PLATFORM.moduleName(
        'features/media/media/audiofiles/list/audio-list-dialog'
      ),
      PLATFORM.moduleName(
        'features/media/media/audiofiles/list/link-audio-group-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/conversation/interaction/input-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/conversation/interaction/incoming-interaction-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/conversation/interaction/incoming-task-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/conversation/worktypes/work-type-versions/work-type-versions-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/conversation/worktypes/assigned-flows-dialog/assigned-flows-dialog-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/member/members/confirm-member-remove/confirm-member-remove'
      ),
      PLATFORM.moduleName(
        'features/organisation/member/members/consult-dialog/consult-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/member/routing-status/routing-status-activity-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/member/teams/addteam/add-team-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/auto-logout/add/auto-logout-add'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/dispositioncodes/list/bulk-upload/bulk-upload-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/renameorganisation/rename-organisation'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/saml/saml-configuration'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/ldapsync/ldap-sync'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/post-interaction-processing/add/add-post-interaction-processor'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/post-interaction-processing/add/edit-post-interaction-processor'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/services/addservice/add-service-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/sites/addsite/add-site-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/skillgroups/addskillgroup/add-skill-group-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/skills/addskill/add-skill-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/sms-gateways/add-dialog/add-sms-gateway-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/sms-gateways/accounts/add-dialog/add sms-gateway-account-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/verint-analytics/speech/verint-speech-analytics-voice-worktype-configuration'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/verint-analytics/text/verint-text-analytics-field-mappings'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/verint-analytics/text/verint-text-analytics-worktype-configuration'
      ),
      PLATFORM.moduleName(
        'features/organisation/organisation/verint-analytics/text/verint-text-analytics'
      ),
      PLATFORM.moduleName(
        'features/organisation/reminders/selectAgent/select-agent-dialog'
      ),
      PLATFORM.moduleName('features/organisation/report/forms/disabled-dialog'),
      PLATFORM.moduleName('features/organisation/report/forms/reports-dialog'),
      PLATFORM.moduleName(
        'features/telephony/member/incomingcall/incoming-call-dialog'
      ),
      PLATFORM.moduleName(
        'features/user/person/changeprofileinformation/changeprofileinformation'
      ),
      PLATFORM.moduleName('features/user/person/changepassword/changepassword'),
      PLATFORM.moduleName(
        'features/user/person/changecallforward/change-call-forward'
      ),
      PLATFORM.moduleName(
        'features/user/person/personalvoicemail/personal-voicemail'
      ),
      PLATFORM.moduleName(
        'features/user/person/changeemailsignature/change-email-signature'
      ),
      PLATFORM.moduleName(
        'features/organisation/qualityassurance/performedassessments/viewsearchdialog'
      ),
      PLATFORM.moduleName(
        'features/campaigns/campaign/schedule-dnc-list/schedule-dnc-list-dialog'
      ),
      PLATFORM.moduleName(
        'features/media/media/audiofiles/addaudio/texttospeech/text-to-speech'
      ),
      PLATFORM.moduleName(
        'features/media/media/audiofiles/addaudio/uploadaudio/upload-audio'
      ),
      PLATFORM.moduleName(
        'features/organisation/interactions/waiting-room-configuration/edit-agent-sampling/edit-agent-sampling'
      ),
      PLATFORM.moduleName(
        'features/organisation/interactions/waiting-room-configuration/routing-groups/add/add-routing-groups-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/interactions/waiting-room-configuration/routing-groups/edit/edit-routing-groups-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/webchat/add-dialog/add-webchat-configuration-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/webchat/snippet-dialog/html-snippet-dialog'
      )
    )

    // wizard
    .globalResources(
      PLATFORM.moduleName(
        'features/organisation/qualityassurance/surveys/wizard/survey-properties'
      ),
      PLATFORM.moduleName(
        'features/organisation/qualityassurance/surveys/wizard/survey-questions'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/export-dialog/export-dialog'
      ),
      PLATFORM.moduleName(
        'features/task/agent-matching/view-score-dialog/view-score-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/in-app-alerts/list/dashboard-alerts-dialog/dashboard-alerts-dialog'
      ),
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/assistants/add-assistant-dialog/add-assistant-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/workforcemanagement/in-house-wfm/shift-templates/add-shift-template/add-shift-template-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/workforcemanagement/in-house-wfm/edit-shift-dialog/edit-shift-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/workforcemanagement/in-house-wfm/configure-in-house-wfm-dialog/configure-in-house-wfm-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/workforcemanagement/in-house-wfm/shifts/add-shift-dialog/add-shift-dialog'
      ),
      PLATFORM.moduleName(
        'features/integrations/microsoft-teams-presences/microsoft-teams-presences-dialog'
      ),
      PLATFORM.moduleName(
        'features/integrations/ms-teams/direct-routing/ms-teams-direct-routing-dialog'
      ),
      PLATFORM.moduleName(
        'features/organisation/donotcontact/donotcontact-list-import-dialog'
      )
    );
}
