/**
 * Draw IO :: Feature model :: Update when making feature changes
 * https://www.draw.io/#G15PPElZ3HONpiWPLoQP-F0gIJrxCB-F39
 */
import { LogManager, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';

import { PlaceholderService } from "../../../_common/services/placeholder-service";
import { IntegrationModel } from "../integration-model";
import { FeatureFlagService } from 'zailab.common';
import { VerintPlatformAPIDialog } from '../../organisation/organisation/verint/verint-platform-api';
import organisationService from '../../organisation/organisation/organisation-service';
import { Microsoft365PlatformAPIDialog } from '../microsoft-365/microsoft-365-platform-api-dialog';
import { IntegrationsService } from '../integrations-service';
import { ConfigureMicrosoft365EmailsDialog } from '../microsoft-365/configure-microsoft-365-emails/configure-microsoft-365-emails';
import { MicrosoftTeamsPresencesDialog } from '../microsoft-teams-presences/microsoft-teams-presences-dialog';
import { MsTeamsDirectRoutingDialog } from '../ms-teams/direct-routing/ms-teams-direct-routing-dialog';



const logger = LogManager.getLogger('Integrations List');

@autoinject
export class IntegrationsList {
  private actions = [{
    action: 'view',
    label: 'view',
  }];
  private platformAPIActions = [{
    action: 'configure',
    label: 'Platform API',
    icon: 'edit'
  }];
  private microsoft365Actions = {
    action: 'configureemails',
    label: 'Configure Emails',
    icon: 'email'
  };
  private integrations: IIntegration[] = [];
  private placeholderService: PlaceholderService;
  private placeholders: number = 0;
  private container: Element;
  private integrationToVerify: IntegrationModel = new IntegrationModel();
  public MICROSOFT_365 = 'Microsoft 365 Exchange';
  public MICROSOFT_TEAMS = 'Microsoft Team Presence';

  constructor(
    private router: Router,
    private featureFlagService: FeatureFlagService,
    private dialogService: DialogService,
    private organisationService: organisationService,
    private integrationsService: IntegrationsService
  ) { }

  public activate(): void {

    this.featureFlagService
      .isEnabled('interactionAnalytics')
      .then((interactionAnalyticsEnabled) => {
        if (interactionAnalyticsEnabled) {
          this.getWorkForceManagementData();
        } else {
          this.generatePlaceholders();
        }
      });

    this.featureFlagService
      .isEnabled('microsoft365')
      .then((microsoft365) => {
        if (microsoft365) {
          this.getMicrosoft365Config();
        }
        this.generatePlaceholders();
      });

    this.generatePlaceholders();
  }

  public getWorkForceManagementData(): void {

    this.organisationService.getWorkforceManagement()
      .then((response) => {
        this.integrations.unshift({ name: 'Verint', hasIcon: true, actions: [].concat(this.actions, this.platformAPIActions), ...response });
        this.generatePlaceholders();
      });
  }

  private async getMicrosoft365Config(): Promise<void> {
    let existingMicrosoftIntegration = this.integrations.find(integration => integration.name === this.MICROSOFT_365);
    if (existingMicrosoftIntegration) {
      let config: IMicrosoft365IntegrationConfig[] = await this.integrationsService.getMicrosoft365Config();
      existingMicrosoftIntegration.config = config[0];
      return;
    }
    try {
      let microsoftIntegration: any = {
        name: this.MICROSOFT_365,
        hasIcon: false,
        actions: [...this.actions]
      };
      let config: IMicrosoft365IntegrationConfig[] = await this.integrationsService.getMicrosoft365Config();

      if (config && config.length === 1) {
        microsoftIntegration.config = config[0] || null;
        microsoftIntegration.actions.push({
          ...this.microsoft365Actions
        });
      } else {
        microsoftIntegration.actions.push({
          ...this.microsoft365Actions
        });
      }
      this.integrations.push(microsoftIntegration);
    } catch (e) {
      logger.warn(' > Failed to retrieve microsoft 356 config due to ', e);
    }
  }

  public attached(): void {
    this.generatePlaceholders();
  }


  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(this.container, this.integrations.length, 3, (placeholders) => {
      this.placeholders = placeholders;
    });
    if (this.placeholderService) {
      //@ts-ignore
      this.placeholders = this.placeholderService.generatePlaceholders(this.integrations.length);
    }
  }

  public selectIntegration(integration: IIntegration): void {
    if (integration.name === 'Verint') {
      this.router.navigate(`verint`);
    } else if (integration.name === this.MICROSOFT_365) {
      this.configureMicrosoft365Dialog();
    } else if (integration.name === this.MICROSOFT_TEAMS) {
      this.viewTeamsPresence();
    }
  }

  private configureMicrosoft365Dialog(): void {
    let microsoftIntegration = this.integrations.find(integration => integration.name === this.MICROSOFT_365);

    this.dialogService
      .open({ viewModel: Microsoft365PlatformAPIDialog, model: microsoftIntegration.config || undefined })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          let _integration = response.output;

          let microsoftIntegration = this.integrations.find(integration => integration.name === this.MICROSOFT_365);
          this.integrations.splice(this.integrations.indexOf(microsoftIntegration), 1, {
            ...microsoftIntegration,
            ..._integration
          });
          setTimeout(() => this.getMicrosoft365Config(), 2000);
        }
      });
  }

  public configureMicrosoft365Emails(): void {
    let microsoftIntegration = this.integrations.find(integration => integration.name === this.MICROSOFT_365);
    if (!microsoftIntegration.config) {
      return;
    }
    this.dialogService.open({
      viewModel: ConfigureMicrosoft365EmailsDialog,
      model: microsoftIntegration.config || undefined
    });
  }

  public configurePlatformApi(integration: IIntegration): void {

    this.dialogService
      .open({ viewModel: VerintPlatformAPIDialog, model: integration })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          let _integration = response.output;
          this.integrations.splice(0, 1, {
            ...integration,
            ..._integration
          });
          this.router.navigate('verint');
        }
      });
  }

  public viewMsTeamsDirectRouting(): void {
    this.dialogService.open({ viewModel: MsTeamsDirectRoutingDialog });
  }

  public viewTeamsPresence(): void {
    this.dialogService.open({ viewModel: MicrosoftTeamsPresencesDialog });
  }

  public msTeamsMenuAction(integration: IIntegration): void {
    if (integration.name === this.MICROSOFT_TEAMS) {
      this.viewTeamsPresence();
    }
  }
}